//Tailwind
import './scss/app.scss';

//Alpine JS
import CustomAlpine from "./js/alpine";
import Appear from "@explose/appear";

//Highway
import Highway from '@dogstudio/highway';

//Highway Renderers
import HomeRenderer from "./js/highway/renderer/home-renderer";

//Highway Transitions
import Fade from "./js/highway/transition/fade";


/***
 * Alpine
 * -----------------------------------------------
 */

const appear = new Appear({
  animations: {
    slideIn: {
      from: { yPercent: 20, opacity: 0 },
      to: { yPercent: 0, opacity: 1 },
      duration: 1,
      ease: 'Power4.easeInOut',
    }
  }
});


/***
 * Highway
 */

// Call Highway.Core once.
// Store it in a variable to use events
window.H = new Highway.Core({
        renderers: {
            homepage: HomeRenderer,
            default: HomeRenderer
        },
        transitions: {
            name: Fade,
            default: Fade
        }
    }
);

// Listen Events

window.H.on('NAVIGATE_OUT', ({ to }) => {
});

window.H.on('NAVIGATE_IN', ({ to }) => {
  appear.update();
});


window.addEventListener('load', () =>{
});
