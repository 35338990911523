import Highway from '@dogstudio/highway';
import { gsap } from "gsap";

export default class Fade extends Highway.Transition {

    out({ from, trigger, done }) {

      gsap.fromTo(
        document.querySelector('.js-mask'),
        {
          scaleX: 0,
          transformOrigin: 'left center'
        },
        {
          scaleX: 1,
          duration: .5,
          ease: 'Power4.easeInOut',
          onComplete: function() {
            window.scrollTo(0, 0);
            done();
          }
        }
      );

    }

    in({ from, to, trigger, done }) {
      from.remove();

      gsap.fromTo(
        document.querySelector('.js-mask'),
        {
          scaleX: 1,
          transformOrigin: 'right center'
        },
        {
          scaleX: 0,
          duration: .5,
          ease: 'Power4.easeInOut',
          onComplete: function() {
            done();
          }
        }
      );

    }

}
