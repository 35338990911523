import Alpine from "alpinejs";
import { gsap } from "gsap";
import Plyr from "plyr";
import axios from "axios";

const configAxios = {
  crossdomain: true,
  headers: {
    "Access-Control-Allow-Origin": "https://1535.lu",
    "Content-Type": "application/json",
    "Access-Control-Allow-Methods": "HEAD, GET, POST, PUT, PATCH, DELETE",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
  },
};
let apiMembers = {'categories':[]};

// Retrieve data from 1535 API
axios
.get("https://1535.lu/fr/api/members", configAxios)
.then(function (response) {
  apiMembers = response.data;
  
  document.addEventListener("alpine:init", () => {

    Alpine.store("memberId", 0);

    // Member List
    Alpine.data("membersList", () => ({
      data: apiMembers,
      categories: null,
      init() {
        this.categories = this.data.categories;
      },
    }));

    // Member Detail
    Alpine.data("memberDetail", () => ({
      data: apiMembers,
      member: null,
      video01Loading: true,
      video02Loading: true,
      init() {
        
        window.removeEventListener("keydown", preventScroll);

        if (Alpine.store("memberId") === undefined) {
          window.H.redirect(window.location.origin + "/" + "list.html");
          return false;
        }
        for (let category of this.data.categories) {
          for (let member of category.members) {
            if (parseInt(member.id) === Alpine.store("memberId")) {
              this.member = member;
            }
          }
        }
        this.$nextTick(() => {
          let playerConfig = {
            muted: true,
            storage: false,
            controls: false,
            autoplay: true,
            playsinline: true,
            loop: { active: true },
            vimeo: {
              background: true,
              quality: "540p",
            },
          };
          if (this.member.video_02 != null) {
            let player01 = new Plyr(".js-player-01", playerConfig);
            player01.on("play", (event) => {
              this.video01Loading = false;
            });
          }
          if (this.member.video_03 != null) {
            let player02 = new Plyr(".js-player-02", playerConfig);
            player02.on("play", (event) => {
              this.video02Loading = false;
            });
          }
        });
      },
    }));

    // Move Focus
    Alpine.data("moveFocus", () => ({
      links: null,
      active: null,
      init() {

        // Prevent scroll on keydown
        window.addEventListener("keydown", preventScroll);

        setTimeout(
          function () {
            this.links = Array.from(document.querySelectorAll("a"));
            this.moveOnFirst();
          }.bind(this),
          100
        );
      },
      moveOnFirst() {
        this.setFocus(this.links[Object.keys(this.links)[0]]);
      },
      moveOnLast() {
        this.setFocus(this.links[Object.keys(this.links)[this.links.length - 1]]);
      },
      moveUp() {
        let active = this.links.find((el) => el === this.active);
        let activeIndex = this.links.indexOf(active);
        let previous = this.links[Object.keys(this.links)[activeIndex - 1]];
        if (previous) {
          previous.scrollIntoView({ block: 'center' });
          this.setFocus(previous);
        } else {
          this.moveOnLast();
        }
      },
      moveDown() {
        let active = this.links.find((el) => el === this.active);
        let activeIndex = this.links.indexOf(active);
        let next = this.links[Object.keys(this.links)[activeIndex + 1]];
        if (next) {
          next.scrollIntoView({ block: 'center' });
          this.setFocus(next);
        } else {
          this.moveOnFirst();
        }
      },
      setFocus(link) {
        this.active = link;
        link.focus();
      },
    }));
  
    // Inactivity Time
    Alpine.data("inactivityTime", (duration) => ({
      time: 0,
      redirect() {
        if (window.H.properties.slug != "homepage") {
          window.H.redirect(window.location.origin + "/" + "index.html");
        }
      },
      resetTimer() {
        clearTimeout(this.time);
        this.time = setTimeout(this.redirect, duration);
      },
    }));
  
  });

  Alpine.start();

})
.catch(function (error) {
  console.log(error);
});

function preventScroll(e) {
  if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
    e.preventDefault();
  }
}
